export const JQ_FEATURE_FLAG_NAMES = [
  'jqp-itinerary-summary',
  'jqp-vouchers',
  'jqp-campaign-badge-carrot',
  'jqp-show-new-package-page',
  'jqp-pricing-strategy',
  'jqp-package-page-v2-analytics',
  'jqp-clear-document-referrer',
  'jqp-destination-view-sort-order',
  'jqp-search',
  'jqp-adyen-key',
  'jqp-ga4-events',
  'jqp-oauth-features',
  'jqp-package-page-v3', // https://jira.qantas.com.au/browse/MAD-1
];

export const QH_FEATURE_FLAG_NAMES = [
  'oauth',
  'qhp-test-flag',
  'package-page',
  'qhp-package-page-v2-analytics',
  'qhp-search',
  'qhp-ga4-events',
  'qhp-adyen-key',
  'qhp-oauth-features',
  'qhp-package-page-v3', // https://jira.qantas.com.au/browse/MAD-1
];
