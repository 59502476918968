import { USE_POINTS_PLUS_PAY_STORAGE_KEY } from '@/constants';
import useSessionStorage from '@/hooks/useSessionStorage';

const useUsePointsPlusPay = () => {
  const [usePointsPlusPay, setUsePointsPlusPay] = useSessionStorage(
    USE_POINTS_PLUS_PAY_STORAGE_KEY,
    false,
  );

  return {
    usePointsPlusPay,
    setUsePointsPlusPay,
  };
};

export default useUsePointsPlusPay;
