import { ComponentProps, ReactNode, useMemo } from 'react';
import { Box } from '@qga/roo-ui/components';
import type { AlertBannerVariants } from '../AlertBanner';

type BannerContainerProps = {
  children: ReactNode;
  variant: AlertBannerVariants;
} & ComponentProps<typeof Box>;

const BannerContainer = ({
  children,
  variant,
  ...rest
}: BannerContainerProps) => {
  const variantStyles = useMemo(() => {
    switch (variant) {
      case 'info':
        return { backgroundColor: 'alert.info' };
      case 'warning':
        return {
          backgroundColor: 'alert.warning',
        };
      default:
        return {};
    }
  }, [variant]);

  return (
    <Box py={4} {...variantStyles} {...rest}>
      {children}
    </Box>
  );
};

export default BannerContainer;
