export const SessionStorage = {
  CHECKOUT_FORM_DATA: 'checkoutFormData',
  CHECKOUT_FORM_STEP: 'checkoutFormStep',
  EXTRAS_FORM_STEP: 'extrasFormStep',
  SEARCH_PARAMS_STORAGE_KEY: 'SEARCH_PARAMS_STORAGE_KEY',
  OAUTH_SESSION_KEY: 'oauth_state',
  USE_POINTS_STORAGE_KEY: 'USE_POINTS_STORAGE_KEY',
  HIDE_BUILD_INFO: 'HIDE_BUILD_INFO',
};

export const SessionStorageDefaultValue: {
  [key in keyof typeof SessionStorage]: any;
} = {
  CHECKOUT_FORM_DATA: [],
  CHECKOUT_FORM_STEP: undefined,
  EXTRAS_FORM_STEP: undefined,
  SEARCH_PARAMS_STORAGE_KEY: {},
  OAUTH_SESSION_KEY: '',
  USE_POINTS_STORAGE_KEY: false,
  HIDE_BUILD_INFO: false,
};

export const Cookie = {
  QF_VALUE: 'QF_VALUE',
  LSL_USER_ID: 'lsl_user_id',
  QH_SESS: 'qh_sess',
  QL_WL_SESSION: 'qlff_wl_sess',
};
