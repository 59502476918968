import { Container, Paragraph } from '@qga/roo-ui/components';
import { AlertIcon, BannerContainer } from './components';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import withProps from '@/v2/../components/withProps';
import BlockContent from '@sanity/block-content-to-react';
import { ComponentProps, ReactNode, FC } from 'react';

const Content = styled(Container)`
  display: flex;
  gap: 0.5rem;
`;

const TextContent = withProps({
  fontSize: '18px',
  mb: 0,
  lineHeight: '1.5',
})(Paragraph);

const BasicLink = styled.a`
  color: currentColor;
`;

export type AlertBannerVariants = 'warning' | 'info';

export type AlertBannerProps = {
  variant: AlertBannerVariants;
  markdown?: string;
  sanityBlocks?: ComponentProps<typeof BlockContent>['blocks'];
  children?: ReactNode;
  noIcon?: boolean;
  LinkComponent?: FC<any>;
};

const AlertBanner = ({
  variant = 'info',
  markdown,
  sanityBlocks,
  children,
  noIcon = false,
  LinkComponent,
}: AlertBannerProps) => {
  return (
    <BannerContainer data-testid="ALERT_BANNER" variant={variant}>
      <Content px={[4, 4, 3]}>
        {!noIcon && <AlertIcon variant={variant} />}
        {children ||
          (markdown && (
            <ReactMarkdown
              source={markdown}
              renderers={{
                paragraph: TextContent,
                link: BasicLink,
              }}
            />
          )) ||
          (sanityBlocks && (
            <BlockContent
              blocks={sanityBlocks}
              serializers={{
                types: { block: TextContent },
                marks: {
                  link: withProps(({ mark }: { mark: any }) => ({
                    href: mark.href,
                    ...(!!LinkComponent ? { sanityBlocks } : {}),
                    ...(mark.blank
                      ? {
                          target: '_blank',
                          rel: 'noopener noreferrer nofollow external',
                        }
                      : {}),
                  }))(LinkComponent || BasicLink),
                },
              }}
            />
          ))}
      </Content>
    </BannerContainer>
  );
};

AlertBanner.Warning = ({
  children,
  ...props
}: Omit<AlertBannerProps, 'variant'>) => (
  <AlertBanner {...props} variant="warning">
    {children}
  </AlertBanner>
);

AlertBanner.Info = ({
  children,
  ...props
}: Omit<AlertBannerProps, 'variant'>) => (
  <AlertBanner {...props} variant="info">
    {children}
  </AlertBanner>
);

export default AlertBanner;
