import { Icon } from '@qga/roo-ui/components';
import type { AlertBannerVariants } from '@/v2/components/ui/AlertBanner/AlertBanner';

const AlertIcon = ({ variant }: { variant: AlertBannerVariants }) => {
  switch (variant) {
    case 'warning':
      return <Icon data-testid="ALERT_BANNER_ICON" name="warningOutline" />;
    case 'info':
    default:
      return null;
  }
};

export default AlertIcon;
