import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { BASE_PATH } from '../../config/public';

const getCorrelationIdHeader = () => {
  const correlationId = uuidv4();

  return {
    'X-Correlation-Id': correlationId,
  };
};

export const port = process.env.PORT || 3000;
export const bffService = `${BASE_PATH}/api`;
export const baseHeaders = {};

const getAPIRequest = (ctx = {}) => {
  const { req } = ctx;

  const baseURL = req ? `http://localhost:${port}${bffService}` : bffService;

  const headers = {
    ...baseHeaders,
    ...getCorrelationIdHeader(),
  };

  const instance = axios.create({
    baseURL,
    headers,
  });

  return instance;
};

export default getAPIRequest;
