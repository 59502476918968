/* eslint-disable prefer-destructuring */
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};

export const BUILD_ID = process.env.BUILD_ID || publicRuntimeConfig?.BUILD_ID;
export const ENVIRONMENT =
  process.env.ENVIRONMENT || publicRuntimeConfig?.ENVIRONMENT;

export const RELEASE = `qp-${BUILD_ID}-${ENVIRONMENT}`;

export const SANITY_PROJECT_ID =
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ||
  process.env.NEXT__SANITY_PROJECT_ID ||
  publicRuntimeConfig.NEXT__SANITY_PROJECT_ID;
export const SANITY_DATASET =
  process.env.NEXT_PUBLIC_SANITY_DATASET ||
  process.env.NEXT__SANITY_DATASET ||
  publicRuntimeConfig.NEXT__SANITY_DATASET;

export const BASE_PATH =
  process.env.BASE_PATH || publicRuntimeConfig?.BASE_PATH || '';
