import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { SWRConfig } from 'swr';
import uuidv4 from 'uuid/v4';
import cookie from '@/lib/cookie';
import { QFF_AUTH_COOKIE } from '@/constants';
import { BASE_PATH } from '@/config/public';

const getAuthorizationHeader = () => {
  const data = cookie().get(QFF_AUTH_COOKIE.name);

  if (!data) {
    return {};
  }

  const accessToken = data.split('|')[1];

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getCorrelationIdHeader = () => ({
  'X-Correlation-Id': uuidv4(),
});

export const fetcher = (url) => {
  const baseURL = `${BASE_PATH}/api`;
  const headers = {
    ...getCorrelationIdHeader(),
    ...getAuthorizationHeader(),
  };

  const instance = axios.create({
    baseURL,
    headers,
  });

  return instance.get(url).then((response) => response.data);
};

const SWRProvider = ({ children }) => (
  <SWRConfig
    value={{
      shouldRetryOnError: false,
      fetcher,
    }}
  >
    {children}
  </SWRConfig>
);

SWRProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SWRProvider;
