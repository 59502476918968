import useViewPromotionEvent from '@/v2/hooks/useViewPromotionEvent';
import { SanityBlocks } from '@/libs/v2/utils/parseSanityBlocksToString';
import AlertBanner from '../AlertBanner';
import DataLayerAnchor from './components/DataLayerAnchor';

export type PromotionBannerProps = {
  sanityBlocks?: SanityBlocks;
};

const PromotionBanner = ({ sanityBlocks }: PromotionBannerProps) => {
  useViewPromotionEvent({ sanityBlocks });

  return (
    <AlertBanner
      variant="info"
      sanityBlocks={sanityBlocks}
      LinkComponent={DataLayerAnchor}
    />
  );
};

export default PromotionBanner;
