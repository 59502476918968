import axios from 'axios';
import uuidv4 from 'uuid/v4';
import { BASE_PATH } from '../../config/public';
import { QFF_AUTH_COOKIE } from '../../constants';
import cookie from '../../lib/cookie';

const getAuthorizationHeader = (ctx) => {
  const data = cookie(ctx).get(QFF_AUTH_COOKIE.name);

  if (!data) {
    return {};
  }

  const accessToken = data.split('|')[1];

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getCorrelationIdHeader = () => {
  const correlationId = uuidv4();

  return {
    'X-Correlation-Id': correlationId,
  };
};

const getCookieHeader = (ctx) => {
  const { req } = ctx;

  if (!req) {
    return {};
  }

  return {
    Cookie: req.headers.cookie || '',
  };
};

export const port = process.env.PORT || 3000;
export const bffService = `${BASE_PATH}/api`;
export const baseHeaders = {};

const getAPIRequest = (ctx = {}) => {
  const { req } = ctx;

  const baseURL = req ? `http://localhost:${port}${bffService}` : bffService;

  const headers = {
    ...baseHeaders,
    ...getCookieHeader(ctx),
    ...getCorrelationIdHeader(),
    ...getAuthorizationHeader(ctx),
  };

  const instance = axios.create({
    baseURL,
    headers,
  });

  return instance;
};

export default getAPIRequest;
