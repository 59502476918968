import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { omit } from 'ramda';
import { useSessionStorage } from 'react-use';
import {
  SessionStorage,
  SessionStorageDefaultValue,
} from '@/libs/v2/constants/storage';

const useUsePoints = () => {
  const router = useRouter();
  const [usePoints, setUsePoints] = useSessionStorage(
    SessionStorage.USE_POINTS_STORAGE_KEY,
    SessionStorageDefaultValue.USE_POINTS_STORAGE_KEY,
  );

  useEffect(() => {
    if (router.query.payWith) {
      setUsePoints(router.query.payWith === 'points');

      router.replace(
        {
          pathname: router.pathname,
          query: { ...omit(['payWith'], router.query) },
        },
        undefined,
        { shallow: true },
      );
    }
  }, [router, setUsePoints]);

  return {
    usePoints,
    setUsePoints,
  };
};

export default useUsePoints;
