import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { POINTS_STORAGE_KEY } from '@/constants';
import useSessionStorage from '@/hooks/useSessionStorage';
import useUsePoints from './hooks/useUsePoints';
import usePreviousRoute from './hooks/usePreviousRoute';
import useUsePointsPlusPay from './hooks/useUsePointsPlusPay';

export const CustomerSelectionContext = React.createContext();

const CustomerSelectionProvider = ({ children }) => {
  const { usePoints, setUsePoints } = useUsePoints();
  const [points, setPoints] = useSessionStorage(POINTS_STORAGE_KEY, {});
  const previousRoute = usePreviousRoute();
  const { usePointsPlusPay, setUsePointsPlusPay } = useUsePointsPlusPay();

  const value = useMemo(
    () => ({
      previousRoute,
      usePoints,
      setUsePoints,
      points,
      setPoints,
      usePointsPlusPay,
      setUsePointsPlusPay,
      paymentType: usePointsPlusPay
        ? 'pointsPlusPay'
        : usePoints
          ? 'points'
          : 'cash',
    }),
    [
      previousRoute,
      usePoints,
      setUsePoints,
      points,
      setPoints,
      usePointsPlusPay,
      setUsePointsPlusPay,
    ],
  );

  return (
    <CustomerSelectionContext.Provider value={value}>
      {children}
    </CustomerSelectionContext.Provider>
  );
};

CustomerSelectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomerSelectionProvider;
